<template>
  <div class="px-5">
    <v-row>
      <v-col cols="12">
        <div class="text-h5 primary--text my-2">
          ตั้งค่า จดหมายแจ้งเตือนต่ออายุ
        </div>
      </v-col>

      <!-- <v-col cols="5" class="text-left mt-2">
        <v-btn color="error" small outlined href="https://manual.permtang.com/documentation/setting/6-setup-sms/" target="_blank""
          ><v-icon small right class="mr-2 mt-1">mdi-book-settings</v-icon> 
          ดูการตั้งค่า SMS
           </v-btn>
      </v-col> -->
    </v-row>
    <div
      dense
      flat
      rounded
      outlined
      style="
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        border-opacity: 0.2;
      "
      class="px-7 py-7"
    >
      <v-row>
        <v-col cols="6">
          <span>เปิดใช้งานจดหมายแจ้งเตือนต่ออายุ</span>
          <v-radio-group
            class="mb-0 mt-0"
            row
            v-model="form.mail_setting.mail_service"
          >
            <v-radio name="" label="ปิด" :value="false"></v-radio>
            <v-radio name="" label="เปิด" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row v-if="form.mail_setting.mail_service" class="mt-0">
        <v-col cols="6" class="pb-0 mb-0">
          <span>แสดง "คุณ" นำหน้าชื่อลูกค้า</span>
          <v-radio-group
            class="mb-4 mt-0"
            row
            v-model="form.mail_setting.show_khun"
          >
            <v-radio name="" label="ไม่แสดง" :value="false"></v-radio>
            <v-radio name="" label="แสดง" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="form.mail_setting.mail_title"
            label="หัวเรื่องจดหมาย"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-text-field
            v-model="form.mail_setting.mail_title"
            label="ตัวอย่าง หัวเรื่องจดหมาย"
            outlined
            disabled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-textarea
            v-model="form.mail_setting.paragraph_1"
            label="ย่อหน้าที่ 1"
            outlined
            dense
            rows="7"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-textarea
            label="ตัวอย่าง ย่อหน้าที่ 1"
            v-model="examParagraph1"
            dense
            readonly
            filled
            rows="7"
          ></v-textarea>
        </v-col>
        <v-col cols="6" style="margin-top: -50px">
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="carPlate1()"
            >เลขทะเบียน จังหวัด</v-btn
          >
          <v-btn
            color="error"
            small
            outlined
            class="ml-5"
            @click="taxout_date1()"
            >วันภาษีขาด</v-btn
          >
          <v-btn
            color="green"
            small
            outlined
            class="ml-5"
            @click="rd_insurance_enddate1()"
            >วันพ.ร.บ.ขาด</v-btn
          >
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="insurance_end_date1()"
            >วันประกันภัยขาด</v-btn
          >
        </v-col>
        <v-col cols="6"></v-col>

        <v-col cols="6">
          <v-textarea
            v-model="form.mail_setting.paragraph_2"
            label="ย่อหน้าที่ 2"
            outlined
            dense
            rows="7"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-textarea
            label="ตัวอย่าง ย่อหน้าที่ 2"
            v-model="examParagraph2"
            dense
            readonly
            filled
            rows="7"
          ></v-textarea>
        </v-col>
        <v-col cols="6" style="margin-top: -50px">
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="carPlate2()"
            >เลขทะเบียน</v-btn
          >
          <v-btn
            color="error"
            small
            outlined
            class="ml-5"
            @click="taxout_date2()"
            >วันภาษีขาด</v-btn
          >
          <v-btn
            color="green"
            small
            outlined
            class="ml-5"
            @click="rd_insurance_enddate2()"
            >วันพ.ร.บ.ขาด</v-btn
          >
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="insurance_end_date2()"
            >วันประกันภัยขาด</v-btn
          >
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="6">
          <v-textarea
            v-model="form.mail_setting.paragraph_3"
            label="ย่อหน้าที่ 3"
            outlined
            dense
            rows="7"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-textarea
            label="ตัวอย่าง ย่อหน้าที่ 3"
            v-model="examParagraph3"
            dense
            readonly
            filled
            rows="7"
          ></v-textarea>
        </v-col>

        <v-col cols="6" style="margin-top: -50px">
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="carPlate3()"
            >เลขทะเบียน</v-btn
          >
          <v-btn
            color="error"
            small
            outlined
            class="ml-5"
            @click="taxout_date3()"
            >วันภาษีขาด</v-btn
          >
          <v-btn
            color="green"
            small
            outlined
            class="ml-5"
            @click="rd_insurance_enddate3()"
            >วันพ.ร.บ.ขาด</v-btn
          >
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="insurance_end_date3()"
            >วันประกันภัยขาด</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <div>
      <v-col cols="12" class="text-right mt-5"
        ><v-btn color="grey" outlined class="ml-5 px-5" @click="close()"
          >ปิด</v-btn
        >
        <v-btn color="primary" class="ml-5 mr-10 px-5" @click="save()"
          >บันทึกข้อมูล</v-btn
        ></v-col
      >
    </div>
  </div>
</template>


<script>
import * as moment from 'moment';

export default {
  data: () => ({
    form: {
      mail_setting: {
        mail_service: true,
        mail_title: '',
        show_khun: false,
        paragraph_1: '',
        paragraph_2: '',
        paragraph_3: '',
      },
    },
    paragraph_1: '',
    paragraph_2: '',
    paragraph_3: '',
    txt_1: '',
    txt_2: '',
    txt_3: '',
    car_plate: 'ตย-1111',
    today: moment().add(29, 'days').format(),
    to32day: moment().add(32, 'days').format(),
    to18day: moment().add(18, 'days').format(),
  }),
  mounted() {
    this.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    close() {
      this.$router.push({ name: 'setting-business-branch' });
    },
    getData() {
      let body = {
        token: this.$jwt.sign({ branch_id: this.branch_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          this.paragraph_1 = this.form.mail_setting.paragraph_1;
          this.paragraph_2 = this.form.mail_setting.paragraph_2;
          this.paragraph_3 = this.form.mail_setting.paragraph_3;
          //console.log(this.form);
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    async save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.form = { branch_id: this.branch_id, ...this.form };
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/branch/edit_branch`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          location.reload();
        }
      );
    },
    carPlate1() {
      this.form.mail_setting.paragraph_1 += ' [ทะเบียนรถ] ';
    },
    taxout_date1() {
      this.form.mail_setting.paragraph_1 += ' [วันภาษีขาด] ';
    },
    rd_insurance_enddate1() {
      this.form.mail_setting.paragraph_1 += ' [วันพรบขาด] ';
    },
    insurance_end_date1() {
      this.form.mail_setting.paragraph_1 += ' [วันประกันขาด] ';
    },
    carPlate2() {
      this.form.mail_setting.paragraph_2 += ' [ทะเบียนรถ] ';
    },
    taxout_date2() {
      this.form.mail_setting.paragraph_2 += ' [วันภาษีขาด] ';
    },
    rd_insurance_enddate2() {
      this.form.mail_setting.paragraph_2 += ' [วันพรบขาด] ';
    },
    insurance_end_date2() {
      this.form.mail_setting.paragraph_2 += ' [วันประกันขาด] ';
    },
    carPlate3() {
      this.form.mail_setting.paragraph_3 += ' [ทะเบียนรถ] ';
    },
    taxout_date3() {
      this.form.mail_setting.paragraph_3 += ' [วันภาษีขาด] ';
    },
    rd_insurance_enddate3() {
      this.form.mail_setting.paragraph_3 += ' [วันพรบขาด] ';
    },
    insurance_end_date3() {
      this.form.mail_setting.paragraph_3 += ' [วันประกันขาด] ';
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    formatParagraph(paragraph) {
      return paragraph
        .replace('[ทะเบียนรถ]', 'กง-1111 กทม')
        .replace('[วันภาษีขาด]', this.formatDate(this.today))
        .replace('[วันพรบขาด]', this.formatDate(this.to18day))
        .replace('[วันประกันขาด]', this.formatDate(this.to32day));
    },
  },
  computed: {
    examParagraph1() {
      return this.formatParagraph(this.form.mail_setting.paragraph_1);
    },
    examParagraph2() {
      return this.formatParagraph(this.form.mail_setting.paragraph_2);
    },
    examParagraph3() {
      return this.formatParagraph(this.form.mail_setting.paragraph_3);
    },
  },
};
</script>